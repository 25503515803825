import React from "react"
import { Link } from "gatsby"
import Layout from "../components/layout"
import SEO from "../components/seo"
import ImageMeta from "../components/ImageMeta"
import SeamlessEmbed from "../components/SeamlessEmbed"

const SmilesForTeachers = () => {
  return (
    <Layout language="en">
      <SEO
        title="Smiles For Teachers program"
        description="Nalchajian Orthodontics will provide one local teacher with FREE aligner treatment or braces treatment to recognize and celebrate their hard work."
      />

      <div className="sft-23">
        <div className="sft-23__container">
          <ImageMeta
            className="sft-23__logo"
            publicId="Programs/2023-sft-logo"
          />

          <ImageMeta
            className="sft-23--desktop"
            publicId="Programs/2023-sft-hero-image"
          />
          <ImageMeta
            className="sft-23--mobile"
            publicId="Programs/2023-sft-hero-image-mobile"
          />
        </div>

        <h1 className="sft-23--hidden">
          Gifting a New Smile to a Local Teacher
        </h1>

        <div className="sft-23__section">
          <div className="sft-23__container">
            <div className="sft-23__grid-2x2">
              <div>
                <p>
                  Teachers play an instrumental role in shaping the future and
                  helping others succeed. Through the Smiles For Teachers
                  program, the team at Nalchajian Orthodontics will provide one
                  local teacher with FREE{" "}
                  <Link
                    to="/services/invisalign-fresno-clovis-ca/"
                    title="Learn more about aligner treatment">
                    Invisalign<sup>®</sup>
                  </Link>{" "}
                  or{" "}
                  <Link
                    to="/services/braces-fresno-ca/"
                    title="Learn more about braces">
                    braces
                  </Link>{" "}
                  treatment.
                </p>
                <p>
                  All current educators are encouraged to apply for this smile
                  makeover opportunity!
                </p>
              </div>
              <div>
                <h2>Applying is as easy as 1, 2, 3</h2>
                <div className="sft-23__icon-list">
                  <ImageMeta publicId="Programs/2023-sft-number-one" />
                  <p>Answer a few simple questions</p>
                </div>
                <div className="sft-23__icon-list">
                  <ImageMeta publicId="Programs/2023-sft-number-two" />
                  <p>Upload two clear photos</p>
                </div>
                <div className="sft-23__icon-list">
                  <ImageMeta publicId="Programs/2023-sft-number-three" />
                  <p>Click Submit</p>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="sft-23__section">
          <div className="sft-23__container">
            <h2>Program Timeline</h2>
            <p>
              Applications will be accepted from now through Saturday, June
              14th, 2024. After the submission period closes, our team will
              review each application and select semi-finalists. The
              semi-finalists will be notified by email.
            </p>

            <p>
              If you know an outstanding teacher who lives or works in or near
              Fresno, Clovis, or Madera, they could receive free orthodontic
              treatment! Please share this opportunity with them and encourage
              them to apply.
            </p>

            <p>
              <i>
                Learn more about{" "}
                <Link
                  to="/services/invisalign-fresno-clovis-ca/"
                  title="Learn more about aligner treatment">
                  Invisalign
                </Link>{" "}
                or{" "}
                <Link
                  to="/services/braces-fresno-ca/"
                  title="Learn more about braces">
                  braces
                </Link>{" "}
                to see how these treatments can benefit your smile.
              </i>
            </p>
          </div>
        </div>

        <div className="sft-23__form has-text-centered">
          <div className="sft-23__container">
            <h4>
              Thank you for your interest in our Smiles For Teachers program.
              Our 2024 application period is closed, but stay tuned on our
              Facebook page to hear about the voting period!
            </h4>
          </div>
        </div>
      </div>
    </Layout>
  )
}

export default SmilesForTeachers
